<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4>
            <v-alert type="error" colored-border elevation="2" border="left">
              <strong>Error 404</strong>
              <br />Por favor, haga click
              <router-link to="/">aqui</router-link>&nbsp;para volver a la
              pagina inicial.
            </v-alert>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style></style>
