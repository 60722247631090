<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" lg="6" xl="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <v-alert
                  :value="error"
                  border="left"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  <strong v-if="code">{{ code }}</strong>
                  {{ message }}
                </v-alert>

                <v-form ref="form">
                  <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="mdi-account-outline"
                    type="text"
                    v-model="email"
                    :rules="rules.email"
                    @keyup.enter="$refs.password.focus()"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock-outline"
                    type="password"
                    v-model="password"
                    :rules="rules.password"
                    @keyup.enter="login"
                    ref="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text :to="{ name: 'reset' }">
                  Restablecer Contraseña
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login" :loading="loading">
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-app>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import errors from '@/mixins/errors';

import { required } from '@/validators/required';
import { email } from '@/validators/email';

import EximyrLoading from '@/components/EximyrLoading.vue';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
  },

  data: () => ({
    email: '',
    password: '',
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
      isAuthenticated: 'isAuthenticated',
      autologout: 'autologout',
      loading: 'loading',
    }),

    // Validation errors
    rules() {
      return {
        email: [required(this), email(this)],
        password: [required(this)],
      };
    },
  },

  methods: {
    login() {
      // Clear all errors
      this.clearErrors();

      if (!this.$refs.form.validate()) {
        return;
      }

      const { email, password } = this;

      this.$store
        .dispatch('security/login', { email, password })
        .then(() => {
          /**
           * Authentication
           */
          const token_type = localStorage.getItem('token_type');
          const access_token = localStorage.getItem('access_token');
          axios.defaults.headers.common['Authorization'] = token_type + access_token;

          // Fire the logged in event
          // window.events.$emit('logged-in');

          if (this.isAdmin || this.isEmployee) {
            // We must redirect it to the restaurants view
            this.redirectToRestaurants();
          } else {
            // Redirect to orders
            this.redirectToOrders();
            // this.$router.push({ name: 'home' });
          }

        })
        .catch(error => {
          this.storeError(error);
        });
    },

    redirectToRestaurants() {
      this.$router.push({
            name: 'administration.restaurants',
          });
    },

    redirectToOrders() {
      // Load all restaurants
      this.$store
        .dispatch('restaurants/load')
        .then(response => {
          // If there are no restaurants
          if (!response.data[0].uuid) {
            return this.$router.push({ name: 'notfound' });
          }

          // Redirect to the orders
          this.$router.push({
            name: 'orders',
            params: { restaurant: response.data[0].uuid },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

<style></style>
