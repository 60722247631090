<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" lg="6" xl="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Restablecer contraseña</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" @submit.prevent="sendResetPasswordEmail">
                <v-card-text>
                  <v-alert
                    :value="error"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                    transition="scale-transition"
                  >
                    <strong v-if="code">{{ code }}</strong>
                    {{ message }}
                  </v-alert>

                  <v-alert
                    :value="!!status"
                    border="left"
                    colored-border
                    type="success"
                    elevation="2"
                    transition="scale-transition"
                  >
                    {{ status }}
                  </v-alert>

                  <v-text-field
                    v-model="email"
                    label="Correo electrónico"
                    name="email"
                    prepend-icon="mdi-email-outline"
                    type="text"
                    :rules="rules.email"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn text :to="{ name: 'login' }">Login</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" color="primary">
                    Restablecer contraseña
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-app>
</template>

<script>
import errors from '@/mixins/errors';

import { required } from '@/validators/required';
import { email } from '@/validators/email';

import { mapGetters } from 'vuex';

import EximyrLoading from '@/components/EximyrLoading.vue';

export default {
  mixins: [errors],

  data: () => ({
    response: '',
    email: '',

    status: false,
    interval: null,
  }),

  components: { EximyrLoading },

  computed: {
    ...mapGetters('security', {
      loading: 'loading',
    }),

    rules() {
      return {
        email: [required(this), email(this)],
      };
    },
  },

  methods: {
    sendResetPasswordEmail() {
      // Clear interval first
      clearInterval(this.interval);

      // Clear all errors
      this.clearErrors();

      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store
        .dispatch('security/sendResetPasswordEmail', { email: this.email })
        .then(response => {
          console.log(response.data);

          // Clear the email address.
          this.email = '';
          this.$refs.form.reset();

          // Show success message.
          this.status = response.data.status;

          // Clear out the success message
          this.interval = setInterval(() => {
            this.status = false;
          }, 3000);
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

<style></style>
