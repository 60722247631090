<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" lg="6" xl="4">
            <v-form ref="form" @submit.prevent="resetPassword">
              <v-card class="elevation-12">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Restablecer contraseña</v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                  <v-alert
                    :value="error"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                  >
                    <strong v-if="code">{{ code }}</strong>
                    {{ message }}
                  </v-alert>

                  <v-alert
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    Hola, <br />
                    Por favor, establece tu contraseña.
                  </v-alert>

                  <v-text-field
                    label="Correo electrónico"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                    :value="email"
                    disabled
                    autocomplete="usename"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :rules="rules.password"
                    autocomplete="new-password"
                  ></v-text-field>

                  <v-text-field
                    id="confirmed"
                    label="Confirma Contraseña"
                    name="confirmed"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="confirmed"
                    :rules="rules.confirmed"
                    autocomplete="new-password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :loading="loading">
                    Restablecer Contraseña
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import errors from '@/mixins/errors';

import { required } from '@/validators/required';
import { equalsTo } from '@/validators/equalsTo';
import { min } from '@/validators/min';

import EximyrLoading from '@/components/EximyrLoading.vue';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
  },

  data: () => ({
    token: '',
    email: '',
    password: '',
    confirmed: '',
  }),

  computed: {
    ...mapGetters('security', {
      isAuthenticated: 'isAuthenticated',
      autologout: 'autologout',
      loading: 'loading',
    }),

    // Validation errors
    rules() {
      return {
        password: [required(this), min(this, 6)],
        confirmed: [required(this), equalsTo(this, this.password)],
      };
    },
  },

  created() {
    this.logout();

    // Get the email from the route
    this.email = this.$route.query.email;
    this.token = this.$route.params.token;
  },

  methods: {
    /**
     * Logout the user if its logged in
     */
    logout() {
      if (this.isAuthenticated) {
        // Logout the user if their are logged in
        this.$store.dispatch('security/logout');
      }
    },

    /**
     * Sets the password and validates the email address.
     */
    resetPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const payload = {
        email: this.email,
        token: this.token,
        password: this.password,
        password_confirmation: this.password,
      };

      this.$store
        .dispatch('security/resetPassword', payload)
        .then(() => {
          // Go to login now
          this.$router.push({ name: 'login' });
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

<style></style>
